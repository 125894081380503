<template>
  <CRow>
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader>
          <CCol>
            <strong>{{ $lang.studentProject.crud.view }}</strong>
          </CCol>
        </CCardHeader>
        <CCardBody>
          <CTabs add-tab-classes="mt-1">
            <span @click="selected = 'ProjectView'">
              <CTab>
                <template slot="title">
                  {{ $lang.studentProject.crud.detail }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'ProjectSkill'">
              <CTab>
                <template slot="title">
                  {{ $lang.studentProject.crud.skill }}
                </template>
              </CTab>
            </span>
          </CTabs>
          <component :is="selected" @changeSelected="getTab($event)" style="margin-top: 20px"></component>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import {Mixin} from "/src/mixins/index.js";
import ProjectView from "./tab/ProjectView";
import ProjectSkill from "./tab/ProjectSkill";
import BackToList from "/src/views/backToList.vue";

export default {
  name: "Project View",
  mixins: [Mixin],
  components: {
    ProjectView,
    ProjectSkill,
    BackToList,
  },
  data() {
    return {
      userId: "",
      tabs: ["ProjectView","ProjectSkill"],
      selected: "ProjectView",
      currentId: "",
      backToListFlag: "",
    };
  },
  mounted() {
    let self = this;
    self.currentId = this.$route.params.id;
  },
  methods: {
    backToList(userId) {
      if (userId != null) {
        this.$router.push({path: "/profile/all/" + userId});
      }
    },
    getTab(tab) {
      let self = this;
      self.selected = tab;
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
