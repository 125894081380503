<template>
  <CRow>
    <CCol col="12" lg="12">
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>
        {{ err_msg }}
      </CAlert>
      <table class="table table-bordered">
        <tr>
          <th>{{ $lang.studentProject.form.projectName }}</th>
          <td>{{ students.projectName }}</td>
        </tr>
        <tr>
          <th>{{ $lang.studentProject.form.description }}</th>
          <td>{{ students.description }}</td>
        </tr>
        <tr>
          <th>{{ $lang.studentProject.form.duration }}</th>
          <td>{{ students.duration }}</td>
        </tr>
        <tr>
          <th>{{ $lang.studentProject.form.noMember }}</th>
          <td>{{ students.noMember }}</td>
        </tr>
        <tr>
          <th>{{ $lang.studentProject.form.singleHandley }}</th>
          <td v-if="students.singleHandley == 0">No</td>
          <td v-if="students.singleHandley == 1">Yes</td>
        </tr>
        <tr>
          <th>{{ $lang.studentProject.form.yourRole }}</th>
          <td>{{ students.yourRole }}</td>
        </tr>
        <tr>
          <th>{{ $lang.studentProject.form.student }}</th>
          <td>{{ students.student }}</td>
        </tr>
      </table>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import store from "/src/store/store.js";
import { student } from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
export default {
  name: "ProjectDetail",
  mixins: [Mixin],
  data() {
    return {
      submitted: false,
      err_msg: "",
      dismissCountDown: 0,
      self: this,
      module: student,
      userOptions: [],
      editId: this.$route.params.id,
      students: {
        description : "",
        duration : "",
        noMember : "",
        projectName : "",
        singleHandley : "",
        yourRole : "",
        student : "",
      },
    };
  },
  mounted() {
    let self = this;
    store.commit("showLoader", false); // Loader Off
    self.dismissCountDown = 0;
    axios
        .get('student/project/'+self.editId)
        .then((response) => {
          let student = response.data.data;
          self.students.description = student.description;
          self.students.duration = student.duration;
          self.students.noMember = student.noMember;
          self.students.projectName = student.projectName;
          self.students.singleHandley = student.singleHandley;
          self.students.yourRole = student.yourRole;
          self.students.student = student.student.firstName;
          store.commit("showLoader", false); // Loader Off
        })
        .catch(function (error) {
          store.commit("showLoader", false); // Loader Off
        });
  },
  methods: {
    open(url) {
      let self = this;
      self.viewImage = url;
      self.largeModal = true;
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
